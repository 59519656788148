<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4 lg4 xl4>
        <v-card class="elevation-12">
          <v-toolbar>
            <v-toolbar-title>Восстановление пароля</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid" lazy-validation>
              <v-text-field
                id="password"
                label="Придумайте новый пароль"
                prepend-icon="mdi-lock"
                v-model="password"
                autofocus
                type="password"
                :rules="[
                  ...passwordRules,
                  password === confirm_password || 'Пароли должны совпадать',
                ]"
                required
              ></v-text-field>
              <v-text-field
                id="confirm_password"
                label="Повторите пароль"
                prepend-icon="mdi-lock"
                v-model="confirm_password"
                type="password"
                :rules="[
                  password === confirm_password || 'Пароли должны совпадать',
                ]"
                required
              >
              </v-text-field>
              <v-checkbox
                v-model="checkbox"
                :rules="[
                  (v) =>
                    !!v || 'Согласен с уловиями политики конфиденциальности',
                ]"
                label="Принимаю условия"
                required
              ></v-checkbox>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="login">К авторизации</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              @click="setNewPassword(confirm_password)"
              :disabled="isDisabled"
              >Установить пароль
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-dialog v-model="showPopUp" width="500">
          <v-card>
            <v-card-title>{{ message.title }}</v-card-title>
            <v-card-text>
              {{ message.content }}
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      valid: false,
      password: "",
      confirm_password: "",
      passwordRules: [
        (v) => !!v || "Обязательное для заполнения",
        (v) =>
          (v && v.length >= 6) || "Пароль должен содержать минимум 6 символов",
      ],
      checkbox: false,
      showPopUp: false,
      status: false,
      message: {},
    };
  },
  methods: {
    async login() {
      this.$router.push("/login");
    },
    //     async newPass(password) {
    //         try {
    //       console.log(password);
    //       this.updatePass(password)

    //       let response = await this.setNewPassword(password);
    //       console.log(response, 'this is response')
    //       this.updatePass('')
    // if (response) {
    // this.message.title = response.data.status === true ? 'Успех!' : 'Ошибка'
    // this.message.content = response.data.status === true ? response.data.message + ' Теперь вы можете войти с новым паролем. Вернитесь к авторизации.' : response.data.message
    // this.status = response.data.status
    // this.showPopUp = true
    // }

    //         } catch (e) {
    //         this.updatePass('')
    //         this.message.title = 'Error'
    //         this.message.content = e
    //             console.log(e)}

    //     },

    ...mapActions(["setNewPassword"]),
    // ...mapMutations(["updatePass"]),
  },
  computed: {
    isDisabled: function () {
      return !this.valid;
    },
  },
};
</script>